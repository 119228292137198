import styled from 'styled-components';
import {NavLink as Link} from "react-router-dom";
import {CgHome, CgProfile} from "react-icons/cg";
import {FiGithub, FiGitlab} from "react-icons/fi";


export const NavLink = styled(Link)`
    color: #fff;
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: center;
    text-decoration: none;
    height: 100%;
    transition: 300ms;
    cursor: pointer;
    &.active {
        color: lightskyblue;
    }
`;

export default function Navigation() {
    return (
        <>
            <div className="absolute right-10 top-0 bottom-0 m-auto w-min h-min">
                <ul className="flex flex-col gap-5 text-4xl">
                    <li>
                        <NavLink to="/">
                            <CgHome/>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/About">
                            <CgProfile/>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="https://github.com/hacimertgokhan">
                            <FiGithub/>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="https://gitlab.com/hacimertgokhan">
                            <FiGitlab/>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}